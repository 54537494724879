<template>
  <div class='platform-parameter mainContainer'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
    <div class="parameterCont">
    <div class="leftDiv">
      <div class="pageCont">
        <div class="title_name">
          <div>参数配置</div>
          <div>
            <!-- <el-button type="text" @click="checkForm('dioform')" v-if="isAuth('sys:param:edit')"><i class="el-icon-s-promotion"></i>保存</el-button> -->
          </div>
        </div>
        <div class="left_list">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="200px" class="mg20" size="small">
          <el-form-item 
            v-for="(item,index) in inform.formData" :key="index" 
            :label="item.paramName" 
            :prop="`formData[${index}].paramValue`" 
            :rules="{required: true, message: `请输入${item.paramName}`, trigger: 'blur'}">
            <template v-if="item.paramCode=='PARAM_CODE_MAP_TYPE'">
              <el-select v-model="item.paramValue" :placeholder="`请选择${item.paramName}`">
                <el-option v-for="item in dicObj.typeData" :key="item.dictValue" :label="item.dictName" :value="item.dictValue"/>
              </el-select>
            </template>
            <template v-else-if="item.paramCode=='PARAM_CODE_EQUIPMENT_JOIN_NETWORK_TYPE'">
              <el-select v-model="item.paramValue" :placeholder="`请选择${item.paramName}`">
                <el-option label="局域网" value="0"/>
                <el-option label="公网" value="1"/>
              </el-select>
            </template>
            <template v-else-if="item.paramCode=='PARAM_CODE_REAL_VIDEO_BITSTREAM_TYPE'">
              <el-select v-model="item.paramValue" :placeholder="`请选择${item.paramName}`">
                <el-option label="主码流" value="1"/>
                <el-option label="子码流" value="2"/>
              </el-select>
            </template>
            <template v-else-if="item.paramCode=='PARAM_CODE_MAP_STATUS'">
              <el-radio-group v-model="item.paramValue">
                <el-radio v-for="item in dicObj.statusData" :key="item.dictValue" :label="item.dictValue">{{item.dictName}}</el-radio>
              </el-radio-group>
            </template>
            <template v-else-if="item.paramCode=='PARAM_CODE_EQUIPMENT_JOIN_SERVER_PWD'||item.paramCode=='PARAM_CODE_USER_ACCOUNT_INIT_PWD'||item.paramCode=='PARAM_CODE_USER_SIP_ACCOUNT_INIT_PWD'">
              <el-input type="text" v-model="item.paramValue" show-password :placeholder="`请输入${item.paramName}`"></el-input>
            </template>
            <template v-else>
              <el-input type="text" v-model="item.paramValue" :placeholder="`请输入${item.paramName}`"></el-input>
            </template>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="checkForm('dioform')" v-if="isAdmin"><i class="el-icon-s-promotion"></i>保存</el-button>
          </el-form-item>
        </el-form>
        </div> 
      </div>
    </div>
    <div class="rightDiv">
      <div class="title_nameRight">
        <div><b style="color:red;">注：</b>点击地图选中中心点坐标(可在左侧输入框中查看经纬度)</div>
      </div>
      <a-map-comp ref="amapRef" :isSearch="false" :height="mapHeight" @clickPoint="clickPoint" :key="uuidNum"/>
      
    </div>
    </div>
    
  </div>
</template>
<script>
import breadCrumb from '@/components/bread-crumb.vue'
import AMapComp from '@/components/AMap.vue'
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
import { bd09togcj02,gcj02tobd09,wgs84togcj02,gcj02towgs84 } from '@/utils/positionUtil';//百度坐标与gps坐标转换方法
import {getUUID } from '@/utils/utils'
var vm;
export default {
  name:'platform-parameter',
  data() {
    return {
      inform :{
        formData:[]
      },
      mapHeight:'calc(100vh - 123px)',

      rules:{
        // formData: { required: true, message: '请输入', trigger: 'blur'}
      },

      dicObj :{
        typeData:[],//类型
        statusData:[],//状态
      },

      uuidNum:'',

      isAdmin:false,//是否是超级管理员
    }
  },
  components:{
    breadCrumb,
    AMapComp,
  },
  created() {
    vm = this
    this.uuidNum = getUUID()

    let userInfo = this.vtp.get('userInfo')
    if(userInfo){
      this.isAdmin = userInfo.isAdmin
    }
  },

  async mounted(){
    // await vm.getCurrDics()
    
    vm.sysParamGetAll()
  },


  //方法
  methods:{
    //校验form
    checkForm (formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          vm.BatchUpdateParamValue()
        } else {return false}
      })
    },
    //批量更新参数
    async BatchUpdateParamValue (){
      let arr = [],lng,lat;
      for(let i in vm.inform.formData){
        if(vm.inform.formData[i].paramCode=='PARAM_CODE_MAP_CENTRE_LNG'){
          lng = parseFloat(vm.inform.formData[i].paramValue)
        }
        if(vm.inform.formData[i].paramCode=='PARAM_CODE_MAP_CENTRE_LAT'){
          lat = parseFloat(vm.inform.formData[i].paramValue)
        }
      }
      if(lng&&lat){
        let gcj02towgs84Val = gcj02towgs84(lng,lat);//火星坐标转GPS坐标
        for(let i in vm.inform.formData){
          if(vm.inform.formData[i].paramCode=='PARAM_CODE_MAP_CENTRE_LNG'){
            vm.inform.formData[i].paramValue = gcj02towgs84Val[0]
          }
          if(vm.inform.formData[i].paramCode=='PARAM_CODE_MAP_CENTRE_LAT'){
            vm.inform.formData[i].paramValue = gcj02towgs84Val[1]
          }
        }
      }
      for(let i in vm.inform.formData){
        let obj = {
          id:vm.inform.formData[i].id,
          paramValue:vm.inform.formData[i].paramValue
        }
        arr.push(obj)
      }
      
      const res = await apiUrl.BatchUpdateParamValue(arr)
      if(res?.code==200){
        vm.$message.success('保存成功')
        this.uuidNum = getUUID();//通过不同的key让amap组件重新加载
        vm.sysParamGetAll()
      }else{
        vm.$message.error(res.message)
      }
    },
    //地图组件点击返回
    clickPoint (val){
      // let bd09togcj02Val = bd09togcj02(val.point.lng,val.point.lat);//百度坐标转火星坐标
      // let gcj02towgs84Val = gcj02towgs84(bd09togcj02Val[0],bd09togcj02Val[1]);//火星坐标转百度坐标
      for(var i in vm.inform.formData){
        if(vm.inform.formData[i].paramCode=='PARAM_CODE_MAP_CENTRE_LNG'){
          vm.inform.formData[i].paramValue = val.lnglat.lng
          // vm.inform.formData[i].paramValue = gcj02towgs84Val[0]
        }
        if(vm.inform.formData[i].paramCode=='PARAM_CODE_MAP_CENTRE_LAT'){
          vm.inform.formData[i].paramValue = val.lnglat.lat
          // vm.inform.formData[i].paramValue = gcj02towgs84Val[1]
        }
      }
    },
    //获取所有系统参数
    async sysParamGetAll(){
      const res = await apiUrl.sysParamGetAll()
      if(res?.code==200){
        let lng,lat;
        for(let i in res.data){
          if(res.data[i].paramCode=='PARAM_CODE_MAP_CENTRE_LNG'){
            lng = res.data[i].paramValue
          }
          if(res.data[i].paramCode=='PARAM_CODE_MAP_CENTRE_LAT'){
            lat = res.data[i].paramValue
          }
        }
        if(lng&&lat){
          let wgs84togcj02Val = wgs84togcj02(lng,lat);//GPS坐标转火星坐标
          // let gcj02tobd09Val = gcj02tobd09(wgs84togcj02Val[0],wgs84togcj02Val[1]);//火星坐标转百度坐标
          for(let i in res.data){
            if(res.data[i].paramCode=='PARAM_CODE_MAP_CENTRE_LNG'){
              res.data[i].paramValue = wgs84togcj02Val[0]
            }
            if(res.data[i].paramCode=='PARAM_CODE_MAP_CENTRE_LAT'){
              res.data[i].paramValue = wgs84togcj02Val[1]
            }
          }
        }
        vm.inform.formData = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //获取系统参数
    // async sysParamList (type) {
    //   let dataObj = {
    //     'paramDomain': type
    //   }
    //   const res = await apiUrl.sysParamList(qs.stringify(dataObj))
    //   if(res?.code==200){
    //     if(type=='PARAM_DOMAIN_MAP'){
    //       vm.inform.mapData = res.data
    //       let lng = 0,lat=0;
    //       for(var i in res.data){
            
    //         if(res.data[i].paramCode == 'PARAM_CODE_MAP_CENTRE_LNG'){
    //           lng = res.data[i].paramValue
    //         }
    //         if(res.data[i].paramCode == 'PARAM_CODE_MAP_CENTRE_LAT'){
    //           lat = res.data[i].paramValue
    //         }
            

    //         if(res.data[i].paramCode == 'PARAM_CODE_MAP_TYPE'){
    //           vm.inform.mapType = res.data[i].paramValue;//地图类型
    //         }
    //         if(res.data[i].paramCode == 'PARAM_CODE_MAP_STATUS'){
    //           vm.inform.mapStatus = res.data[i].paramValue;//地图状态
    //         }
            
    //       }
    //       let wgs84togcj02Val = wgs84togcj02(lng,lat);//百度坐标转火星坐标
    //       let gcj02tobd09Val = gcj02tobd09(wgs84togcj02Val[0],wgs84togcj02Val[1]);//火星坐标转百度坐标
    //       vm.inform.lng = parseFloat(gcj02tobd09Val[0]);//中心经度
    //       vm.inform.lat = parseFloat(gcj02tobd09Val[1]);//中心纬度
          
    //     }else if(type=='PARAM_DOMAIN_SIP_SERVER'){
    //       vm.inform.sipData = res.data
    //       for(var i in res.data){
    //         if(res.data[i].paramCode == 'PARAM_CODE_SIP_SERVER_IP'){
    //           vm.inform.address = res.data[i].paramValue
    //         }
    //         if(res.data[i].paramCode == 'PARAM_CODE_SIP_SERVER_PORT'){
    //           vm.inform.port = res.data[i].paramValue
    //         }
    //         if(res.data[i].paramCode == 'PARAM_CODE_SIP_SERVER_DATA_PORT'){
    //           vm.inform.dataPort = res.data[i].paramValue
    //         }
    //         if(res.data[i].paramCode == 'PARAM_CODE_SIP_SERVER_ENTERPRISE_ACCOUNT'){
    //           vm.inform.enterpriseAccount = res.data[i].paramValue
    //         }
    //         if(res.data[i].paramCode == 'PARAM_CODE_SIP_SERVER_ENTERPRISE_TOKEN'){
    //           vm.inform.enterpriseToken = res.data[i].paramValue
    //         }
    //       }
    //     }else if(type=='PARAM_DOMAIN_EQUIPMENT_JOIN_SERVER'){
    //       vm.inform.deviceData = res.data
    //       for(var i in res.data){
    //         if(res.data[i].paramCode == 'PARAM_CODE_EQUIPMENT_JOIN_SERVER_IP'){
    //           vm.inform.deviceAddress = res.data[i].paramValue
    //         }
    //         if(res.data[i].paramCode == 'PARAM_CODE_EQUIPMENT_JOIN_SERVER_HTTP_PORT'){
    //           vm.inform.devicePort = res.data[i].paramValue
    //         }
    //         if(res.data[i].paramCode == 'PARAM_CODE_EQUIPMENT_JOIN_SERVER_SIP_PORT'){
    //           vm.inform.deviceSipPort = res.data[i].paramValue
    //         }
    //         if(res.data[i].paramCode == 'PARAM_CODE_EQUIPMENT_JOIN_SERVER_ID'){
    //           vm.inform.deviceId = res.data[i].paramValue
    //         }
    //         if(res.data[i].paramCode == 'PARAM_CODE_EQUIPMENT_JOIN_SERVER_REALM'){
    //           vm.inform.deviceRealm = res.data[i].paramValue
    //         }
    //         if(res.data[i].paramCode == 'PARAM_CODE_EQUIPMENT_JOIN_SERVER_PWD'){
    //           vm.inform.devicePwd = res.data[i].paramValue
    //         }
    //         if(res.data[i].paramCode == 'PARAM_CODE_EQUIPMENT_JOIN_NETWORK_TYPE'){
    //           vm.inform.deviceType = res.data[i].paramValue
    //         }
    //       }
    //     }else if(type=='PARAM_DOMAIN_EQUIPMENT_STREAM_SERVER'){
    //       vm.inform.streamData = res.data
    //       for(var i in res.data){
    //         if(res.data[i].paramCode == 'PARAM_CODE_EQUIPMENT_STREAM_SERVER_IP'){
    //           vm.inform.streamAddress = res.data[i].paramValue
    //         }
    //         if(res.data[i].paramCode == 'PARAM_CODE_EQUIPMENT_STREAM_SERVER_HTTP_PORT'){
    //           vm.inform.streamPort = res.data[i].paramValue
    //         }
    //       }
    //     }else if(type=='PARAM_DOMAIN_USER'){
    //       vm.inform.userData = res.data
    //       for(var i in res.data){
    //         if(res.data[i].paramCode == 'PARAM_CODE_USER_ACCOUNT_INIT_PWD'){
    //           vm.inform.userInitPw = res.data[i].paramValue
    //         }
    //         if(res.data[i].paramCode == 'PARAM_CODE_USER_SIP_ACCOUNT_INIT_PWD'){
    //           vm.inform.userSipInitPw = res.data[i].paramValue
    //         }
    //       }
    //     }
        
    //   }else{
    //     vm.$message.error(res.message)
    //   }
    // },
    //获取数据字典
    // async getCurrDics(){
    //   let dataObj = ['DOMAIN_MAP_TYPE','DOMAIN_MAP_STATUS']
    //   const res = await apiUrl.getDicts(dataObj)
    //   if(res?.code==200){
    //     vm.dicObj.typeData = res.data.DOMAIN_MAP_TYPE||[];//地图类型
    //     vm.dicObj.statusData = res.data.DOMAIN_MAP_STATUS||[];//地图状态
    //   }else{
    //     vm.$message.error(res.message)
    //   }
    // },
  }
}
</script>
<style lang='scss'>
.platform-parameter{
  .parameterCont{
    display: flex;
  }
  .leftDiv{
    width: 30%;
    flex-shrink: 0;
    .left_list{
      height: calc(100vh - 162px);
      overflow: auto;
    }
    .el-form{
      
    }
  }
  .rightDiv{
    flex-grow: 1;
    width: 70%;
  }
}
</style>
