<template>
	<div class='AMap' :style="{height:height}">
		<div id="container"></div>
	</div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import { AMapConfig } from '@/assets/js/mapConfig'
import {wgs84togcj02,gcj02towgs84} from '@/utils/positionUtil.js'
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
let vm;
export default {
	props:{
		height: {
      type: String,
      default: '346px',
    },
		isSearch: {
      type: Boolean,
      default: true,
    },
	},
	data() {
		return {
			map:null,
			mapInfo:{
				lng:116.397428,
				lat:39.90923,
			},
			homeList:[],
			mapObj:{
				markerInfo:[],
				ctInfo:[],
				circleInfo:[]
			},

			vectorLayer:null,//图层
		}
	},
	watch:{
    '$store.state.csType':async function(newVal,oldVal){
			if(vm.isSearch && oldVal!=''){
				await vm.clearTime()
				vm.getHome()
			}
    }
  },
	created(){
		vm = this
		
	},
	mounted(){
		this.$nextTick(()=>{
			setTimeout(() => {
				vm.sysParamGetMap()
			}, 0);
		})
	},
	methods:{
		//获取系统参数
		async sysParamGetMap () {
			let dataObj = {
				'paramDomain': 'PARAM_DOMAIN_MAP'
			}
			const res = await apiUrl.sysParamGetMap(qs.stringify(dataObj))
			if(res?.code==200){
				let wgs84togcj02Val = wgs84togcj02(res.data.PARAM_CODE_MAP_CENTRE_LNG,res.data.PARAM_CODE_MAP_CENTRE_LAT);//gps转火星坐标
				vm.mapInfo={
					lng:parseFloat(wgs84togcj02Val[0]),//中心经度
					lat:parseFloat(wgs84togcj02Val[1])//中心纬度
				}
				
				vm.mapInit()

			}else{
				vm.$message.error(res.message)
			}
		},
		mapInit(){
			AMapLoader.load({
				...AMapConfig
			}).then((AMap) => {
			// AMap.plugin([
			// 	'AMap.ControlBar', 
			// 	'AMap.ToolBar',
			// 	'AMap.HawkEye',
			// 	'AMap.Geolocation',
			// 	'AMap.CitySearch',
			// 	'AMap.CircleEditor'], function () {//异步加载插件
				vm.map = new AMap.Map('container', {
					rotateEnable: true,//是否开启地图旋转交互 鼠标右键 + 鼠标画圈移动 或 键盘Ctrl + 鼠标画圈移动
					pitchEnable: true, // 是否开启地图倾斜交互 鼠标右键 + 鼠标上下移动或键盘Ctrl + 鼠标上下移动
					zoom: 13.8, //初始化地图层级
					pitch: 40, // 地图俯仰角度，有效范围 0 度- 83 度
					rotation: 0,//初始地图顺时针旋转的角度
					viewMode: '3D', //开启3D视图,默认为关闭
					zooms: [2, 20],//地图显示的缩放级别范围
					center: [vm.mapInfo.lng,vm.mapInfo.lat],//初始地图中心经纬度
					// terrain: true, // 开启地形图
					resizeEnable:true,
					mapStyle:'amap://styles/e8b62587005d3c9654a92329735c3a47',//自定义地图样式
				});
				vm.map.on('click', (e) => {
					// console.log('当前点击经纬度：',e.lnglat.lng,e.lnglat.lat);
					vm.$emit('clickPoint',e)
				});
				vm.map.on('zoomend', (e) => {
					let zoom = vm.map.getZoom()
					if(zoom>=17){
						// this.mapObj.ctInfo = []
						// this.mapObj.circleInfo = []
						for(var i in vm.mapObj.ctInfo){
							vm.mapObj.ctInfo[i].hide()
						}
						for(var i in vm.mapObj.circleInfo){
							vm.mapObj.circleInfo[i].hide()
						}
					}else{
						for(var i in vm.mapObj.ctInfo){
							vm.mapObj.ctInfo[i].show()
						}
						for(var i in vm.mapObj.circleInfo){
							vm.mapObj.circleInfo[i].show()
						}
					}
				});
				if(vm.isSearch){
					// console.log('bbb',vm.$store.state.csType);
					vm.getHome()
				}else{
					var controlBar = new AMap.ControlBar({//控制地图旋转插件
						position: {
							right: '10px',
							top: '10px'
						}
					});
					vm.map.addControl(controlBar);
					var toolBar = new AMap.ToolBar({//地图缩放插件
						position: {
							right: '40px',
							top: '110px'
						}

					});
					vm.map.addControl(toolBar)
				
					//浏览器定位
					var geolocation = new AMap.Geolocation({
						enableHighAccuracy: true, // 是否使用高精度定位，默认：true
						timeout: 10000, // 设置定位超时时间，默认：无穷大
						offset: [10, 10],  // 定位按钮的停靠位置的偏移量
						zoomToAccuracy: true,  //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
						position: 'RB' //  定位按钮的排放位置,  RB表示右下
					})
					geolocation.getCurrentPosition(function(status,result){
						if(status=='complete'){
							//定位成功
							console.log('定位成功',status,result);
						}else{
							//定位失败
							console.log('定位失败',status,result);
						}
					});
					vm.map.addControl(geolocation)
				}
				//IP定位
				// var citySearch = new AMap.CitySearch()
				// citysearch.getLocalCity(function(status, result) {
				// 	if (status === 'complete' && result.info === 'OK') {
				// 		if (result && result.city && result.bounds) {
				// 			var cityinfo = result.city;
				// 			var citybounds = result.bounds;
				// 			document.getElementById('info').innerHTML = '您当前所在城市：'+cityinfo;
				// 			//地图显示当前城市
				// 			map.setBounds(citybounds);
				// 		}
				// 	} else {
				// 		console.log('定位失败',result.info);
				// 	}
        // });
			// });
			}).catch(e => {
				console.log(e)
			})
		},
		//获取场所
		async getHome(){
			console.log(vm.vectorLayer);
			if(vm.$store.state.csType!='' && vm.vectorLayer==null){
				let dataObj = {
					'areaId':vm.$store.state.csType
				}
				const res = await apiUrl.getHome(qs.stringify(dataObj))
				if(res?.code == 200){
					if(res.data?.length>0){
						vm.vectorLayer = new AMap.VectorLayer();
						vm.map.add(vm.vectorLayer);
						for(var i in res.data){
							await vm.csPointFun(res.data[i])
						}
						vm.homeList = res.data
					}
				}
			}
		},
		//场所标点
		csPointFun(item){
			let wgs84togcj02Val = wgs84togcj02(item.longitude,item.latitude)
			if(item.pointTime) clearInterval(item.pointTime); //清除定时器
			var marker = new AMap.Text({
				position: [wgs84togcj02Val[0], wgs84togcj02Val[1]],
				map: vm.map,
				text: item.homeName,
				anchor:'top-center',
				offset: new AMap.Pixel(0, -30),
				style:{
					'background':'none',
					'color':'#67C23A',
					'border':'0px',
					'fontSize':'16px',
				}
			})
			marker.itemInfo = item
			marker.itemInfo.pointTime = null;//定时器
			vm.mapObj.markerInfo.push(marker);
			vm.map.add(marker); //添加marker点
			// marker.dom.classList.add('bus-border') //添加样式类
			
			var circleCt = new AMap.Circle({
				center: [wgs84togcj02Val[0], wgs84togcj02Val[1]], 
				radius:50,
				strokeWeight: 0,
				strokeColor: '#67C23A',
				strokeOpacity: 1,
				fillOpacity: 0.2,
				fillColor: '#67C23A',
			});
			circleCt.itemInfo = item
			vm.mapObj.ctInfo.push(circleCt);
			vm.vectorLayer.add(circleCt);
			var circle = new AMap.Circle({
				center: [wgs84togcj02Val[0], wgs84togcj02Val[1]], 
				radius:50,
				strokeWeight: 1,
				strokeColor: '#67C23A',
				strokeOpacity: 1,
				fillOpacity: 0.6,
				fillColor: '#67C23A',
			});
			circle.itemInfo = item
			vm.mapObj.circleInfo.push(circle);
			vm.vectorLayer.add(circle);

			vm.map.setFitView(vm.mapInfo.markerInfo);//视野缩放范围到所有点

			marker.itemInfo.pointTime = setInterval(function(){
				if(circle.getRadius()<=500){
					circle.setRadius(circle.getRadius()+20);
					circle.setOptions({
						fillOpacity: circle.getOptions().fillOpacity-0.02
					})
				}else{
					circle.setRadius(50);
					circle.setOptions({
						fillOpacity: 0.6
					})
				}
			},50)
		},
		//清除定时器
		async clearTime(){
			if(this.mapObj.markerInfo?.length>0){
				for(var i in this.mapObj.markerInfo){
					if(this.mapObj.markerInfo[i].itemInfo.pointTime){
						await clearInterval(this.mapObj.markerInfo[i].itemInfo.pointTime);
					}
				}
				await this.vectorLayer?.clear()
				await this.map?.clearMap()
				this.mapObj.markerInfo = []
				this.mapObj.ctInfo = []
				this.mapObj.circleInfo = []
				this.vectorLayer = null
			}
		}
	},
	beforeDestroy(){
		if(this.isSearch){
			this.clearTime()
		}
	}
}
</script>
<style lang='scss'>
.AMap{
	#container{
		width: 100%;
		height: 100%;
		.amap-logo,.amap-copyright{
			display: none !important;
		}
		.amap-marker-label{
			background: none;
			border: none;
			font-size: 20px;
		}
	}
}
</style>